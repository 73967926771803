<template>
	<!-- <div class="wrap"> -->
		<div id="app">
			<div id="demo"></div>
		</div>
	<!-- </div>   -->
</template>
 
<script>
import Pdfh5 from 'pdfh5';
import axios from 'axios';
import {getImgToken } from '@/server';
import {getCookie} from '@/lib/utils';
  export default {
	data () {
      return {
			pdfh5: null,
      openId:getCookie('openId'),
			agreementUrl:this.$store.state.authorDetailUrl,
			token:localStorage.getItem('token')||getCookie('token')
      }
    },
    mounted(){
		// sessionStorage.setItem('key', 'authorDetail2');
    //   let that = this;
    //   window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
		// 	if(sessionStorage.getItem('key') == 'authorDetail2') {
		// 		that.$router.push({ path: '/modifyDebitCardDetail' });
		// 	}
    //   },false)
　　},
	created(){
     getImgToken({openid:this.openId}).then(res=>{
      if(res.wx_code=='0'){
        localStorage.setItem("token", res.img_token); 
        this.token = res.img_token;
        this.getPdfUrl()
      }
    })
		
	},
	methods: {
      getPdfUrl(){
        var url = this.agreementUrl+'?Authorization='+this.token;
        axios.get(url, {
          responseType: 'arraybuffer',
        })
        .then(res => {
          this.pdfh5 = new Pdfh5('#demo', {
            data: res.data
          });
          this.disable = false;
        });
      },
    }
  }
</script>

<style>
/* @import '@/lib/pdfh5.css'; */
* {
  padding: 0;
  margin: 0;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>
